#general__header {

	height:$page-header-height-mobile;

	.page__title {
		@include padding-left($site-padding);
		padding-left:$lateral-page-gaps-text;
		
		&:hover {
			a {
				font-style: normal !important;
			}
		}
	}
}

#general {

	@include padding-top($page-padding-top);
	@include padding-bottom(2rem);
	@include padding-left($site-padding);
	width:98.6%;
	@include font-size($fs-s-mobile);
	line-height:1.05;

	a, a:visited, a:active {
		font-style: italic;
	}
	
	.general__wrapper {
		// columns:5;
		// column-width:20%;
		transition: opacity 0.3s ease-in-out;
		opacity:0;
		
		p {
			@include padding-bottom(1rem);
			display: block;
			// display: inline-block;
			
		}
	}
	
	h1 {
		@include padding-top(1rem);
		@include padding-bottom(1rem);
		// @include font-size($fs-m-mobile);
		// width:80%;

		&:first-child {
			padding-top:0
		}

	}
	
	
	h2 {
		@include padding-top(0.5rem);
		@include padding-bottom(1rem);
		width:80%
		// @include font-size($fs-m-mobile);

	}
	

}

@media (min-width: $breakpoint-mobile-to-tablet-hoch) {
	#general {
		@include font-size($fs-s-tablet);
		@include padding-top(9rem);

		.general__wrapper {
			columns:2;
		}

		h1 {
			// @include font-size($fs-m-tablet);
		}
	}
}

@media (min-width: $breakpoint-tablet-hoch-to-tablet-quer) {
	#general__header {
		height:$page-header-height-desktop;
	}
}

@media (min-width: $breakpoint-tablet-quer-to-desktop) {

	#general__header {

		.page__title {
			padding-left:79.8%;
		}
	}

	#general {
		@include padding-top($page-padding-top-desktop);
		@include font-size($fs-s-desktop);

		.general__wrapper {
			columns:5;
			column-width:20%;
			column-fill:auto;
		}

		h1 {
			// @include font-size($fs-m-desktop);
		}
	}

	.firefox {
		#general {

			&.privacy-policy, &.datenschutz, &.datenschutzerklaerung {

				.general__wrapper {
					column-fill:balance !important;
				}
			}
		}
	}

}

// @media (min-width: 1000px) and (max-width: 1400px) {

// 	#general {

// 		.general__wrapper {
// 			columns:4 !important;
// 		}
// 	}
// }

@media (min-width: $breakpoint-tablet-hoch-to-tablet-quer) and (max-width: $breakpoint-tablet-quer-to-desktop) {

	#general__header {

		.page__title {
			padding-left:75%;
		}
	} 
}

@media (min-width: $breakpoint-desktop-to-xl) {

	#general__header {

		.page__title {
			padding-left:83.3333%;
		}
	}

	#general {
		width:99.3%;

		.general__wrapper {
			columns:6;
		}
	}
}